<template>
  <v-container
    fluid
    tag="section"
  >
    <v-data-table
      :items="events"
      :headers="[
          { text: 'Beginn', value: 'startDate', sortable: false },
          { text: 'Liga', value: 'parent.shortName', sortable: false },
          { text: 'Mannschaften', value: 'teams', sortable: false },
          { text: 'Halle', value: 'venue', sortable: false, align: 'center'},
          { text: 'Live-Eingabe', value: 'input', sortable: false, align: 'center' },
          { text: 'Live-Anzeige', value: 'view', sortable: false, align: 'center' }
        ]"
      :mobile-breakpoint="0"
      :items-per-page="-1"
      hide-default-footer
      :item-class="(item) => ({'stbw2023bold': item.status === 'EventMovedOnline', 'stbw2023red': item.status === 'EventStarted'})"
    >
      <template #item.startDate="{item}">
        <i v-if="item.status === 'EventPostponed'"><span style="text-decoration:line-through;">{{ item.startDate | dateformat('DD.MM.YYYY') }}</span><br>wird verschoben,<br>Termin noch offen</i>
        <span v-else :inner-html.prop="item.startDate | dateformat('dddd<br>DD.MM.YYYY<br>HH:mm')" />
      </template>
      <template #item.teams="{item}">
        <div
          v-for="t in item.teams"
          :key="t.team._id"
        >{{ t.team.name }}</div>
      </template>
      <template #item.publicComment="{item}">
        <v-tooltip
          v-if="item.publicComment"
          bottom
        >
          <template
            v-slot:activator="{ on }"
          >
            <v-btn
              v-on="on"
              small
              fab
              text
            >
              <v-icon>
                far fa-info-circle
              </v-icon>
            </v-btn>
          </template>
          <span>
              {{ item.publicComment }}
            </span>
        </v-tooltip>
      </template>
      <template #item.internalComment="{item}">
        <v-tooltip
          v-if="item.internalComment"
          bottom
        >
          <template
            v-slot:activator="{ on }"
          >
            <v-btn
              v-on="on"
              small
              fab
              text
            >
              <v-icon>
                far fa-info-circle
              </v-icon>
            </v-btn>
          </template>
          <span>
              {{ item.internalComment }}
            </span>
        </v-tooltip>
      </template>
      <template #item.venue="{item}">
        <v-tooltip
          v-if="item.venue"
          bottom
        >
          <template
            v-slot:activator="{ on }"
          >
            <v-btn
              v-on="on"
              small
              fab
              text
              @click.stop="openmaps(item.venue.address)"
            >
              <v-icon>
                far fa-circle-h
              </v-icon>
            </v-btn>
          </template>
          <span>
                {{ item.venue.name }}<br>
                {{ item.venue.address.streetAddress }} {{ item.venue.address.streetNumber }}<br>
                {{ item.venue.address.postalCode }} {{ item.venue.address.addressLocality }}
              </span>
        </v-tooltip>
      </template>
      <template #item.input="{item}">
        <v-btn
          text
          fab
          small
          @click.stop="(ev) => input(item._id, ev)"
        >
            <span class="fa-stack fa-1x">
              <i
                class="far fa-play fa-stack-2x"
                style="margin-left:0px;margin-top:-6px;font-size:300%;"
              />
              <i
                class="far fa-pencil-alt fa-stack-1x"
                style="margin-left:4px;margin-top:1px;font-size:100%;text-shadow:0 0 2px white;"
              />
            </span>
        </v-btn>
      </template>
      <template #item.view="{item}">
        <v-btn
          text
          fab
          small
          @click.stop="(ev) => view(item._id, ev)"
        >
            <span class="fa-stack fa-1x">
              <i
                class="far fa-play fa-stack-2x"
                style="margin-left:0px;margin-top:-5px;font-size:300%;"
              />
              <i
                class="far fa-eye fa-stack-1x"
                style="margin-left:4px;font-size:100%;text-shadow:0 0 2px white;"
              />
            </span>
        </v-btn>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { useGraphQL } from '@/plugins/graphql'
import gql from 'graphql-tag'
import Vue from 'vue'

const query = `
  _id
  ... on StbW2023Wk {
    name
    startDate
    teams {
      team { _id name }
      order
    }
    venue {
      _id
      name
      address { streetAddress streetNumber postalCode addressLocality }
    }
    parent {
      _id
      ... on StbW2023League {
        name
        shortName
        order
      }
    }
  }
`

export default {
  name: 'wkinput',

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  data: () => ({
    Events: {}
  }),

  computed: {
    evs () {
      return this.$store.state.profile?.acl?.filter(a => a.type === 'stbwwk' && a?.event?.parent?._parent === this.id)?.map(e => e.id)
    },
    events () {
      return Object.values(this.Events)
    }
  },

  methods: {
    input (id, ev) {
      this.$openlink(`/input/${id}`, ev)
    },
    view (id, ev) {
      this.$openlink(`/view/${id}`, ev)
    },
    fetch (id) {
      this.$apollo.query({
        query: gql`query($id: UUID!) {
          Event(id: $id) { ${query} }
        }`,
        variables: {
          id
        }
      }).then(({ data }) => {
        Vue.set(this.Events, id, data.Event)
      })
    },
    fetchData () {
      this.evs.forEach(id => {
        this.fetch(id)
        this.$apollo.subscribe({
          query: gql`subscription($id: UUID!) {
          EventUpdate(id: $id) { ${query} }
        }`,
          variables: {
            id
          }
        }).subscribe({
          next: ({ data }) => {
            Vue.set(this.Events, data.EventUpdate._id, data.EventUpdate)
          }
        })
      })
    }
  },

  watch: {
    evs () {
      this.fetchData()
    }
  },

  created () {
    this.fetchData()
  }
}
</script>

<style scoped>

</style>
